<template>
  <b-card>

    <validation-observer
      ref="regFormObserver"
      tag="form"
    >
      <b-row
        class="mb-4"
      >
        <b-col
          cols="6"
        >
          <b-form-group
            label="Registration Form for Students"
          >
            <v-select
              v-model="regFormForStudent"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="formsList"
              :clearable="true"
              :placeholder="'Select Form'"
              label="name"
              value="id"
              :reduce="option => option.id"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr>

      <b-row
        class="mb-4"
      >
        <b-col
          cols="6"
        >
          <b-form-group
            label="Registration Form for Parents"
          >
            <v-select
              v-model="regFormForParent"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="formsList"
              :clearable="true"
              :placeholder="'Select Form'"
              label="name"
              value="id"
              :reduce="option => option.id"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr>

      <b-row>
        <b-col
          cols="6"
        >
          <b-form-group
            label="Registration Form for Staff"
          >
            <v-select
              v-model="regFormForStaff"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="formsList"
              :clearable="true"
              :placeholder="'Select Form'"
              label="name"
              value="id"
              :reduce="option => option.id"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col cols="12">
          <b-button
            variant="primary"
            class="mr-1"
            @click="handleForm"
          >
            Save changes
          </b-button>
          <b-button
            variant="outline-secondary"
            :to="{ name: 'admin-instances-list' }"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<style scoped lang="scss">

</style>
<script>
import {
  BButton,
  BCard, BCol,
  BFormCheckbox, BFormCheckboxGroup, BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTags,
  BFormTextarea,
  BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Compact } from 'vue-color'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import store from '@/store'


export default {
  name: 'InstanceTabMain',
  components: {
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormTags,
    BFormTextarea,
    BRow,
    BFormCheckbox,
    BCard,
    BButton,
    BFormRadioGroup,
    BFormInput,

    vSelect,

    ValidationObserver,
    ValidationProvider,

    'compact-picker': Compact,
  },
  computed: {
    ...mapGetters({
      instance: 'app-instances/getInstance',
      formsList: 'app-instances/getFormsList',
    }),
    isLoading: {
      get() {
        return store.getters['app-instances/getIsLoading']
      },
      set(val) {
        store.commit('app-instances/SET_IS_LOADING', val)
      },
    },
  },
  data() {
    return {
      regFormForStudent: null,
      regFormForParent: null,
      regFormForStaff: null,
      regFormTypes: [
        { text: 'Student', value: 'student' },
        { text: 'Parent', value: 'parent' },
        { text: 'Staff', value: 'staff' },
      ],
    }
  },
  async created() {
    await this.setFormsList()
    await this.setRegistrationFormsList()
  },
  methods: {
    async setFormsList() {
      const response = await this.$store.dispatch('app-instances/fetchFormsList', { 'is_active': true })
      store.commit('app-instances/SET_FORMS_LIST', response.data.data)
    },
    async setRegistrationFormsList() {
      const queryParams = {
        school_id: this.instance.id,
      }

      const response = await this.$store.dispatch('app-instances/fetchRegistrationFormsList', queryParams)
      this.regFormForStudent = response.data.data.find(item => item.type === 'student')?.form_id
      this.regFormForParent = response.data.data.find(item => item.type === 'parent')?.form_id
      this.regFormForStaff = response.data.data.find(item => item.type === 'staff')?.form_id
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.regFormObserver.validate().then(success => {
          if (success) {
            resolve(true)
            this.saveForms()
          } else {
            reject()
          }
        })
      })
    },
    async saveForms() {
      this.isLoading = true
      let queryParams = []

      if (this.regFormForStudent) {
        queryParams.push({
          'type': 'student',
          'form_id': this.regFormForStudent,
        })
      }

      if (this.regFormForParent) {
        queryParams.push({
          'type': 'parent',
          'form_id': this.regFormForParent,
        })
      }

      if (this.regFormForStaff) {
        queryParams.push({
          'type': 'staff',
          'form_id': this.regFormForStaff,
        })
      }

      await this.$store.dispatch('app-instances/saveRegistrationFormsList', {
          'id': this.instance.id,
          'data': queryParams
      })
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('create-grade-modal')
        })
    },
  },
}
</script>