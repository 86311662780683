<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="0.7"
      rounded="sm"
    >
      <b-card no-body>
        <b-tabs
          v-model="tabIndex"
          content-class="col-12 mt-1 mt-md-0"
          pills
          fill
          nav-wrapper-class="col-12 py-1 px-0 border-bottom  bg-white"
          nav-class="mb-0 bg-light text-center"
          :disabled="isLoading"
          @activate-tab="tabActivated"
        >
          <b-tab
            active
          >
            <template #title>
              <span class="font-weight-bold">General</span>
            </template>
            <InstanceMainTab
              v-if="instance"
              @nextTab="saveChanges"
            />
          </b-tab>

          <b-tab
            :disabled="!instance.id"
          >
            <template #title>
              <span class="font-weight-bold">Registration Form</span>
            </template>
            <RegistrationFormsTab
              v-if="instance.id"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
    <SaveInstanceModal/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { setActiveTab } from '@core/mixins/setActiveTab'
import { onUnmounted } from '@vue/composition-api'

import {
  BCard,
  BButton,
  BOverlay,
  BTabs,
  BTab
} from 'bootstrap-vue'

import SaveInstanceModal from '@/views/admin/instances/instance-settings/modals/SaveInstanceModal.vue'

import store from '@/store'
import storeModule from '@/views/admin/instances/instancesStoreModule'
import InstanceMainTab from '@/views/admin/instances/instance-tabs/InstanceMainTab.vue'
import RegistrationFormsTab from '@/views/admin/instances/instance-tabs/RegistrationFormsTab.vue'

const initInstance = {
  name: '',
  domain_name: '',
  full_url_path: '',
  public_stripe_key: '',
  private_stripe_key: '',
  logo: null,
  favicon: null,
  type: 1,
  timezone: 'US/Mountain',
  payment_methods: [
    'Credit Card', 'Check', 'Cash', 'ACH', 'PayPal',
  ],
  styling: {
    primary: '#2BA7E0',
    secondary: '#7367F0',
    secondary_bg: '#8618F3',
    primary_button: '#C73CDE',
    secondary_button: '#CC63D6',
  },
  hero: {
    message: '',
    subtitle: '',
    hero_cta_button_text: '',
  },
  // twilio: {
  //   sid: '',
  //   token: '',
  //   from: '',
  // },
  show_tasks_before_app_accepted: true,
  show_login_form: true,
  show_registration_form: true,
  oauth_providers: [],
  hero_img: null,
  footer_img: null,
  footer: {
    copyright: '',
    phone: '',
    email: '',
    address: '',
    facebook: '',
    instagram: '',
    youtube: '',
  },
  modules: [
    'Kanban View', 'Payment Plans', 'Payment Contracts', 'Cashbank',
    'Wallets', 'Staff Registration', 'Young Shluchim verification', 'Custom Fees',
    'Reports',
  ],
  report_ids: [],
  use_custom_main_page_url: false,
  use_terms_and_conditions: false,
  main_page_url: '',
  gender_settings: {
    use_gender: true,
    default_gender: 'Male',
  },
}

export default {
  components: {
    BCard,
    BButton,
    BOverlay,
    BTabs,
    BTab,

    InstanceMainTab,

    SaveInstanceModal,
    RegistrationFormsTab,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  mixins: [setActiveTab],
  computed: {
    ...mapGetters({
      instance: 'app-instances/getInstance',
    }),
    isLoading: {
      get() {
        return this.$store.getters['app-instances/getIsLoading']
      },
      set(val) {
        this.$store.commit('app-instances/SET_IS_LOADING', val)
      },
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-instances'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      store.commit('app-instances/SET_INSTANCE', JSON.parse(JSON.stringify(initInstance)))
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.setInstanceData()
        this.setActiveTab()
      },
    },
    tabIndex(val) {
      this.setTabIndexQuery(val)
    },
  },
  async created() {
    await this.setReportsList()
    await this.setInstanceData()
  },
  methods: {
    async setInstanceData() {
      if (this.$router.currentRoute.name === 'admin-edit-instance') {
        await store.dispatch('app-instances/fetchInstance', this.$router.currentRoute.params.id)
      } else {
        store.commit('app-instances/SET_INSTANCE', JSON.parse(JSON.stringify(initInstance)))
      }
    },
    async saveChanges() {
      this.isLoading = true
      if (this.$router.currentRoute.name === 'admin-edit-instance') {
        await this.updateInstance()
      } else {
        await this.createNewInstance()
      }
      this.isLoading = false
    },
    async createNewInstance() {
      this.$bvModal.show('save-instance-modal')
      await store.dispatch('app-instances/createNewInstance')
    },
    async updateInstance() {
      this.$bvModal.show('save-instance-modal')
      await store.dispatch('app-instances/updateInstance', this.$router.currentRoute.params.id)
    },
    async setReportsList() {
      const response = await this.$store.dispatch('app-instances/fetchReportsList', { is_default_report: true })
      store.commit('app-instances/SET_REPORTS_LIST', response.data.data)
    },
    tabActivated(newTabIndex, oldTabIndex, event) {
      if (this.hasUnsavedChanges) {
        // eslint-disable-next-line no-alert
        if (!window.confirm('There are some changes in the current tab that was not saved. Are you sure you want to leave the tab and discard changes?')) {
          event.preventDefault()
        } else {
          this.hasUnsavedChanges = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.color-picker {
  &.vc-compact {
    width: 100%;
    padding: 10px;
  }
  .vc-compact-colors {
    margin-bottom: 0;
  }
  .vc-compact-color-item {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    margin: 4px;
  }
  .vc-compact-dot {
    position: absolute;
    width: 14px;
    height: 7px;
    top: 7px;
    left: 5px;
    border-left: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    background: transparent;
    border-radius: 0;
    transform: rotate(-45deg);
    opacity: 1;
  }
}
</style>
